/** @jsxImportSource @emotion/react */
import React from 'react'
import type { Item } from './App'
import { base } from './color';

type Props = {
  items: Array<Item>
}

const TIME_HEIGHT = 440

const calc = (min: number) => (TIME_HEIGHT / (60 * 24)) * min

const convMin2Height = (min: number): string => `${calc(min)}px`

export const Card: React.FC<Props> = ({ items }) => {
  const timeline = Array(25)
    .fill(undefined)
    .map((_, i) => `${i}:00`)

  return (
    <div
      css={{
        display: 'inline-block',
        width: '320px',
        borderRadius: '24px',
        overflow: 'hidden',
        backgroundColor: base,
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
      }}
    >
      <div css={{ display: 'flex' }}>
        <div
          css={{
            marginTop: '-10px',
            width: '100px',
            fontSize: '12px',
            fontWeight: 500,
          }}
        >
          {timeline
            .filter((_, index) => index !== timeline.length - 1)
            .map((time, index) => (
              <div
                css={{
                  height:
                    index !== timeline.length - 1
                      ? `${TIME_HEIGHT / (timeline.length - 1)}px`
                      : undefined,
                }}
              >
                {index === 0 ? '' : time}
              </div>
            ))}
        </div>

        <div
          css={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          {items.map((item, index) => (
            <div
              css={{
                height: convMin2Height(item.min),
                // borderBottom:
                //   index !== items.length - 1 ? `1px solid ${base}` : undefined,
                fontSize: '12px',
                lineHeight: convMin2Height(item.min),
                backgroundColor: item.color,
              }}
            >
              {item.text}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
