/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import type { Item } from './App'
import { TwitterPicker } from 'react-color'

// a little function to help us with reordering the result
const reorder = (list: Array<Item>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '4px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 360,
})

type Props = {
  state: Array<Item>
  setState: (input: Array<Item>) => void
}

export const Input: React.FC<Props> = ({ state, setState }) => {
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(state, result.source.index, result.destination.index)

    setState(items)
  }

  const onClickDelete = (index: number) => () => {
    const r = state.filter((_, i) => i !== index)
    setState(r)
  }

  const onInputText = (index: number) => (text: string) => {
    const r = state.map((v, i) => (index === i ? { ...v, text } : v))
    setState(r)
  }

  const onInputMin = (index: number) => (min: number) => {
    const r = state.map((v, i) => (index === i ? { ...v, min } : v))
    setState(r)
  }

  const onChangeColor = (index: number) => (color: string) => {
    const r = state.map((v, i) => (index === i ? { ...v, color } : v))
    setState(r)
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {state.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <InputItem
                      item={item}
                      onClickDelete={onClickDelete(index)}
                      onInputMin={onInputMin(index)}
                      onInputText={onInputText(index)}
                      onChangeColor={onChangeColor(index)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

type InputItemProps = {
  item: Item
  onClickDelete: () => void
  onInputText: (text: string) => void
  onInputMin: (min: number) => void
  onChangeColor: (color: string) => void
}

const InputItem: React.FC<InputItemProps> = ({
  item,
  onClickDelete,
  onInputText,
  onInputMin,
  onChangeColor,
}) => {
  const [openColor, setOpenColor] = useState<boolean>(false)
  return (
    <div css={{ position: 'relative' }}>
      <i
        className="material-icons"
        onClick={onClickDelete}
        css={{
          position: 'absolute',
          top: -20,
          right: -12,
          cursor: 'pointer',
        }}
      >
        clear
      </i>

      <div className="row" css={{ gap: '4px' }}>
        <input
          className="column"
          type="text"
          value={item.text}
          onInput={(event) => onInputText(event.currentTarget.value)}
        />
        <input
          className="column"
          type="time"
          value={convMin2Time(item.min)}
          onInput={(event) =>
            onInputMin(convTime2Min(event.currentTarget.value))
          }
        />
        {/* <button className="button" onClick={() => setOpenColor(prev => !prev)}>色を選択</button> */}
        <div className="column column-10" css={{ textAlign: 'center' }}>
          <div
            onClick={() => setOpenColor((prev) => !prev)}
            css={{
              display: 'block',
              position: 'relative',
              minWidth: '24px',
              height: '24px',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: item.color,
            }}
          >
            {openColor && (
              <div
                css={{ position: 'absolute', left: -8, top: 28, zIndex: 10 }}
              >
                <TwitterPicker onChange={(value) => onChangeColor(value.hex)} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const convMin2Time = (min: number): string => {
  const minute = min % 60
  const hour = (min - minute) / 60

  return `${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}`
}

const convTime2Min = (time: string): number => {
  const [hour, minute] = time.split(':')

  return Number(hour) * 60 + Number(minute)
}
