/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { v4 } from 'node-uuid'
import { useLocalStorage } from 'react-use'

import { Card } from './Card'
import { Input } from './Input'
import { css } from '@emotion/react'
import faunadb, { query as q } from 'faunadb'
import { accent, base } from './color'

export type Item = { id: string; min: number; text: string; color: string }

const getClient = () => {
  return new faunadb.Client({
    secret: process.env.REACT_APP_CLIENT_SECRET as string,
  })
}
const client = getClient()

const DEFAULT_COLOR = accent
const BG_COLOR = base

const headerCss = css`
  position: relative;
  background: #dfefff;
  box-shadow: 0px 0px 0px 5px #dfefff;
  border: dashed 2px white;
  padding: 0.2em 0.5em;
  font-weight: 600;
  color: ${BG_COLOR};

  :after {
    position: absolute;
    content: '';
    left: -7px;
    top: -7px;
    border-width: 0 0 15px 15px;
    border-style: solid;
    border-color: ${BG_COLOR} ${BG_COLOR} #a8d4ff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  }

  margin-bottom: 20px;
`

function App() {
  const [showResult, setIsShowResult] = useState<boolean>(false)
  const [state, setState] = useLocalStorage<Array<Item>>(
    'day-cycle',
    [
      { id: '1', min: 6 * 60, text: '睡眠' },
      { id: '2', min: 0.5 * 60, text: '朝食' },
      { id: '3', min: 1 * 60, text: '通勤' },
      { id: '4', min: 3 * 60, text: '仕事' },
      { id: '5', min: 1 * 60, text: '昼食' },
      { id: '6', min: 5 * 60, text: '仕事' },
      { id: '7', min: 1 * 60, text: '通勤' },
      { id: '8', min: 1 * 60, text: '夕食' },
      { id: '9', min: 0.5 * 60, text: '入浴' },
      { id: '10', min: 5 * 60, text: '睡眠' },
    ].map((v) => ({ ...v, color: DEFAULT_COLOR })),
  )

  return (
    <div css={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      {showResult ? (
        <div css={{ textAlign: 'center', margin: 'auto' }}>
          {/* <div css={headerCss}>ある日の○○○○</div> */}
          <Card items={state || []} />
        </div>
      ) : (
        <WithInput
          state={state || []}
          setState={setState}
          onClickShowResult={() => setIsShowResult(true)}
        />
      )}
    </div>
  )
}

export default App

type WithInputProps = {
  onClickShowResult: () => void
  state: Array<Item>
  setState: React.Dispatch<React.SetStateAction<Array<Item> | undefined>>
}

const WithInput: React.FC<WithInputProps> = ({
  onClickShowResult,
  state,
  setState,
}) => {
  const [id, setId] = useState<string>('')
  const createSchedule = () =>
    client
      .query(
        q.Create(q.Collection('schedule'), {
          data: {
            day: state,
          },
        }),
      )
      .then((ret) => setId((ret as any).ref.value.id))

  const load = () => {
    const get = q.Get(q.Ref(q.Collection('schedule'), id))
    console.log(get)
    client.query(get).then((ret) => setState((ret as any).data.day))
  }

  const addItem = () =>
    setState((prev) =>
      prev
        ? [...prev, { id: v4(), min: 30, text: '', color: DEFAULT_COLOR }]
        : [],
    )

  const sum: number = state.reduce((acc, cur) => acc + cur.min, 0)

  return (
    <div className="container" css={{ marginTop: '20px' }}>
      <div className="row">
        <div className="column" css={{ marginTop: '40px' }}>
          <input
            value={id}
            onInput={(event) => setId(event.currentTarget.value)}
          />
          <button onClick={load}>データを読み込み</button>
          <Card items={state} />
        </div>
        <div className="column">
          <Input state={state} setState={setState} />
        </div>
        <div className="column">
          <div
            css={{
              backgroundColor: '#fcf8ec',
              border: '1px solid',
              borderRadius: '4px',
              padding: '12px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div css={{ height: '48px' }}>
              {id.length > 0 && (
                <div>
                  以下のIDをメモしてください
                  <br />
                  {id}
                </div>
              )}
              {sum === 24 * 60 ? (
                <button className="button" onClick={() => createSchedule()}>
                  保存する
                </button>
              ) : (
                <div>
                  {`${sum - 24 * 60} ${
                    sum - 24 * 60 < 0 ? '不足しています' : '超過しています'
                  }`}
                </div>
              )}
            </div>
            <div>
              <button onClick={addItem}>追加する</button>
            </div>
            <div>
              <button onClick={onClickShowResult}>結果のみ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
