/** @jsxImportSource @emotion/react */

import faunadb, { query as q } from 'faunadb'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Item } from './App'
import { Card } from './Card'

export type Props = {}

const getClient = () => {
  return new faunadb.Client({
    secret: process.env.REACT_APP_CLIENT_SECRET as string,
  })
}
const client = getClient()

export const ResultPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>()
  const [state, setState] = useState<Array<Item>>()

  useEffect(() => {
    const get = q.Get(q.Ref(q.Collection('schedule'), id))
    console.log(get)
    client.query(get).then((ret) => setState((ret as any).data.day))
  }, [id])

  return (
    <div css={{ display: 'flex', justifyContent: 'center', textAlign: 'center', height: '100vh' }}>
      <div css={{ textAlign: 'center', margin: 'auto' }}>
        <Card items={state || []} />
      </div>
    </div>
  )
}
